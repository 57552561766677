// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";

// import fonts
@font-face {
    font-family: 'Surt';
    src: url(../fonts/Surt-Extended-Regular.otf);
}
@font-face {
    font-family: 'Surt-B';
    src: url(../fonts/Surt-Extended-Bold.otf);
}

$pink: #E6007C;
$black: #000;
$grey: #818181;
$gwhite: #E8E8E8;
$main-color-5: #a8a8a8;



h1, h2 {
    font-family: 'Surt';
    font-size: 75px;
    line-height: 70px;
    text-align: left;
    color: $black;
    padding-top: 50px;
}

a {
    transition: all 200ms ease-out;
}

@media (max-width: 767px) {
    h1, h2 {
        font-size: 3rem;
    }
}

body {
    &.page_others {
        background-color: $grey;
        h1, h2 {
            color: #fff;
        }
        a {
            &:hover {
                color: #fff !important;
            }
        }
        .main-body {
            background-color: $grey !important;
        }
        .navbar {
            background: $grey;
        }
        .bar {
            &.bar:before, &.bar:after {
                background-color: #fff;
            }
        }
        .main-nav::before {
            background: $grey;
        }
    }
}

.main-body {
    width: 100%;
    margin-top: 250px;
    background-color: #fff;
    overflow-x: hidden;
    transform-style: preserve-3d;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    background: #fff;
    z-index: 10;
}
.menu {
    max-width: 1880px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .logo {
        padding: 5rem 0;
    }
    .logo-mobile {
        display: none;
        padding: 5rem 0;
    }
}
@media (max-width: 767px) {
    .menu {
        .logo {
            display: none;
        }
        .logo-mobile {
            display: block;
        }
    }
}


.hamburger-menu {
    position: relative;
    height: 4rem;
    width: 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.bar {
    width: 42px;
    height: 6px;
    border-radius: 2px;
    background-color: transparent;
    transition: 0.5s;
    position: relative;
}
.bar:before,
.bar:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: $pink;
    transition: 0.5s;
}
.bar:before {
    transform: translateY(-9px);
}
.bar:after {
    transform: translateY(9px);
}
.main-section {
    position: relative;
    width: 100%;
    left: 0;
    z-index: 5;
    overflow: hidden;
    transform-origin: left;
    transform-style: preserve-3d;
    transition: 0.5s;
}
.device.active .bar {
    transform: rotate(360deg);
    background-color: transparent;
}
.device.active .bar:before {
    transform: translateY(0) rotate(45deg);
    background-color: #000;
}
.device.active .bar:after {
    transform: translateY(0) rotate(-45deg);
    background-color: #000;
}
.main-body.active .main-section {
    animation: main-animation 0.5s ease;
    cursor: pointer;
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
}
@keyframes main-animation {
from {
    transform: translate(0);
}
to {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
}
}
.links {
    position: fixed;
    width: 30%;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
ul {
list-style: none;
}
.links a {
    font-family: 'Surt-B';
    text-decoration: none;
    color: $black;
    padding: 0.7rem 0;
    display: inline-block;
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: 0.3s;
    opacity: 0;
    transform: translateY(10px);
    animation: hide 0.5s forwards ease;
}
.links a:hover {
    color: $pink;
}
.links.active a {
    animation: appear 0.5s forwards ease var(--i);
}
@keyframes appear {
from {
    opacity: 0;
    transform: translateY(10px);
}
to {
    opacity: 1;
    transform: translateY(0px);
}
}
@keyframes hide {
from {
    opacity: 1;
    transform: translateY(0px);
}
to {
    opacity: 0;
    transform: translateY(10px);
}
}
.shadow {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    transform-origin: left;
    transition: 0.5s;
    background-color: white;
}
.shadow.one {
    z-index: -1;
    opacity: 0.15;
}
.shadow.two {
z-index: -2;
opacity: 0.1;
}
.main-body.active .shadow.one {
animation: shadow-one 0.6s ease-out;
transform: perspective(1300px) rotateY(20deg) translateZ(215px) scale(0.5);
}
@keyframes shadow-one {
0% {
    transform: translate(0);
}
5% {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
}
100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(215px) scale(0.5);
}
}
.main-body.active .shadow.two {
animation: shadow-two 0.6s ease-out;
transform: perspective(1300px) rotateY(20deg) translateZ(120px) scale(0.5);
}
@keyframes shadow-two {
0% {
    transform: translate(0);
}
20% {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
}
100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(120px) scale(0.5);
}
}



/*----------------------nav-------------*/

/* Main menu positionning */
.main-nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 35%;
    text-align: center;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all .375s;
}

.main-nav.is-open {
    opacity: 1;
    z-index: 100;
    visibility: visible;
}

.main-nav::before {
	content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -60px;
    background: #fff;
    border-left: 1px solid #e7e7e7;
    transform-origin: 0 0;
    transform: skew(0deg) translateX(120%);
    transition: all .275s .1s;
}

.main-nav.is-open::before {
    transform: skew(0deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 0 110px !important;
    transform: translateX(0%) skew(0deg);
}

.main-nav li {
    display: block;
    margin: .5rem 0;
    text-align: right;
    transform: skew(0deg);
}

/* Apparition effect on links */
.main-nav a {
    opacity: 0;
    transform: translateY(-10px);
}

.main-nav.is-open a {
    opacity: 1;
    transform: translateY(0);
}
.main-nav li:nth-child(1) a {
	transition: all 275ms 175ms
}
.main-nav li:nth-child(2) a {
	transition: all 275ms 225ms
}
.main-nav li:nth-child(3) a {
	transition: all 275ms 275ms
}
.main-nav li:nth-child(4) a {
	transition: all 275ms 325ms
}
.main-nav li:nth-child(5) a {
	transition: all 275ms 375ms
}


/* Decoration */
.main-nav ul,
.main-nav li {
	list-style: none;
	padding: 0;
}
.main-nav a {
	display: block;
	padding: 12px 0;
    font-family: "Surt";
	color: #000;
	font-size: 2em;
	text-decoration: none;
	font-weight: bold;
    &:hover {
        padding-left: 20px;
    }
}

/* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
.open-main-nav {
	position: absolute;
	top: 15px;
	padding-top: 20px;
	right: 15px;
	z-index: 1000;
	background: none;
	border: 0;
	cursor: pointer;
}
.open-main-nav:focus {
	outline: none;
}
.burger {
	position: relative;
	display: block;
	width: 28px;
	height: 4px;
	margin: 0 auto;
	background: #5A3B5D;
	transform: skew(5deg);
	transition: all .275s;
}

.burger:after,
.burger:before {
	content: '';
	display: block;
	height: 100%;
	background: #5A3B5D;
	transition: all .275s;
}

.burger:after {
	transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.burger:before {
	transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.is-open .burger {
	transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
	transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
	transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
}

/* MENU Text part */

.burger-text {
	display: block;
	font-size: .675rem;
	letter-spacing: .05em;
	margin-top: .5em;
	text-transform: uppercase;
	font-weight: 500;
	text-align: center;
	color: #5A3B5D;
}

.device {
    position: absolute;
    max-width: 1880px;
    width: 50%;
    height: 250px;
    margin: 0 auto;
    padding: 0 1.5rem;
    top: 0;
    right: 0;
    display: flex;
    align-content: center;
    align-items: center;
    background: transparent;
    justify-content: flex-end;
    z-index: 999;
}

/* Slow motion button */
[id="slowmo"] {
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 10px;
	border: 0;
	font-size: 1rem;
	background: #FEDC2A;
	color: #5A3B5D;
	font-weight: bold;
	cursor: pointer;
	transition: all .275s;
}

[id="slowmo"] span {
	display: block;
	font-weight: normal;
}

[id="slowmo"]:hover,
[id="slowmo"]:focus {
	background: #5A3B5D;
	color: #FEDC2A;
}

[id="slowmo"].is-slowmo span:after {
	content: 'Activated';
	display: block;
	font-weight: bold;
}

/* When slowmotion is activated */

.is-slowmo + .device .open-main-nav .burger,
.is-slowmo + .device .open-main-nav .burger:before,
.is-slowmo + .device .open-main-nav .burger:after,
.is-slowmo + .device .main-nav,
.is-slowmo + .device .main-nav::before,
.is-slowmo + .device .main-nav a {
	transition-duration: 3s;
}
.is-slowmo + .device .main-nav li:nth-child(1) a {
	transition-delay: 1750ms
}
.is-slowmo + .device .main-nav li:nth-child(2) a {
	transition-delay: 2250ms
}
.is-slowmo + .device .main-nav li:nth-child(3) a {
	transition-delay: 2750ms
}
.is-slowmo + .device .main-nav li:nth-child(4) a {
	transition-delay: 3250ms
}
.is-slowmo + .device .main-nav li:nth-child(5) a {
	transition-delay: 3750ms
}

/* Notice */
.notice {
	position: absolute;
	bottom: -15px;
	left: 0; right: 0;
	padding: 20px;
	background: #F2F2F2;
	color: #5A3B5D;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	z-index: 100;
	text-align: center;
}
.notice strong {
	font-weight: 700;
}
.notice a {
	padding: 2px 3px;
	background: #FEDC2A;
	text-decoration: none;
}

@media (max-width: 1199px) {
    .main-nav {
        width: 50%;
    }
}

@media (max-width: 991px) {
    .main-nav {
        ul {
            padding: 0 75px !important;
        }
        a {
            font-size: 1.5em;
        }
    }
}

@media (max-width: 767px) {
    .main-nav {
        width: 75%;
    }
}

/*----------------------nav-------------*/



footer {
    margin: 0 0 165px;
    .footer-links {
        margin-top: 165px;
        margin-bottom: 125px;
        ul {
            margin: 0;
            padding: 145px 0 145px 0;
            border-top: 2px solid $black;
            border-bottom: 2px solid $black;
            li {
                a {
                    font-family: "Surt";
                    font-weight: 600;
                    font-size: 75px;
                    line-height: 115px;
                    text-align: left;
                    color: $black;
                    text-decoration: none;
                    transition: all 200ms ease-out;
                    &:hover {
                        color: $pink;
                    }
                }
            }
        }
    }
    .footer-copy {
        font-family: "Surt-B";
        font-size: 30px;
        line-height: 70px;
        text-align: left;
        color: $black;
    }
}

@media (max-width: 767px) {
    footer {
        .footer-copy {
            font-size: 20px;
        }
        .footer-links {
            margin-top: 100px;
            margin-bottom: 100px;
            ul {
                padding: 100px 0 100px 0;
                li {
                    a {
                        font-size: 44px;
                        line-height: 70px;
                    }
                }
            }
        }
    }
}

/*----------PROJECTS-----------*/

.projects {
    .header {
        margin-bottom: 145px;
        .header-sub {
            max-width: 65%;
            padding-top: 50px;
            font-family: "Surt";
            font-size: 38px;
            line-height: 50px;
            text-align: left;
            color: #000;
        }

        a {
            padding-top: 50px;
            font-family: "Surt";
            font-size: 38px;
            line-height: 50px;
            text-align: left;
            text-decoration: none;
            color: #fff;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .projects-box {
        display: flex;
        flex-wrap: wrap;
        .b-1, .b-1-2, .b-2-2, .b-2, .b-3 {
            display: block;
            position: relative;
            margin: 0 0 20px;
            a {
                position: relative;
                display: flex;
                height: 100%;
                img {
                    max-width: 100%;
                    border-radius: 10px;
                    object-fit: cover;
                }
                &:hover {
                    .overlay {
                        opacity: 1;
                    }
                }
            }
        }
        .b-1 {
            flex: 0 0 50%;
            padding-right: 10px;
        }
        .b-1-2 {
            display: flex;
            flex: 0 0 50%;
            flex-direction: column;
            gap: 20px;
            padding-right: 10px;
        }
        .b-2-2 {
            display: flex;
            flex: 0 0 50%;
            flex-direction: column;
            gap: 20px;
            padding-left: 10px;
        }
        .b-2 {
            flex: 0 0 50%;
            padding-left: 10px;
        }
        .b-3 {
            flex: 0 0 100%;
        }
    }

    .overlay {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgb(230 0 124 / 90%);
        border-radius: 10px;
        padding: 115px 130px;
        transition: all 200ms ease-out;
        h2 {
            font-family: "Surt";
            font-size: 40px;
            line-height: 70px;
            color: #fff;
            padding: 0;
        }
        & > div {
            display: flex;
            font-family: "Surt";
            font-size: 20px;
            text-align: center;
            color: #fff;
            img {
                margin-right: 30px;
            }
        }
    }
}

@media (max-width: 1399px) {
    .projects {
        .overlay {
            padding: 50px 65px;
        }
    }
}

@media (max-width: 1199px) {
    .projects {
        .overlay {
            padding: 40px 45px;
            h2 {
                font-size: 24px;
                line-height: 30px;
            }
            & > div {
                font-size: 14px;
                img {
                    margin-right: 15px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .projects {
        .header {
            .header-sub {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    .projects {
        .header {
            .header-sub {
                padding-top: 20px;
                font-size: 32px;
            }
        }
        .projects-box {
            .b-1, .b-1-2, .b-2-2, .b-2, .b-3 {
                flex: 0 0 100%;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        .overlay {
            padding: 10px 25px;
            h2 {
                margin-bottom: 0;
                font-size: 24px;
                line-height: 50px;
            }
            & > div {
                font-size: 14px;
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}

.contact {
    .header {
        margin-bottom: 145px;
        h1 {
            color: $black;
        }
    }
    .contact-email {
        margin-top: 35px;
        margin-bottom: 35px;
        a {
            font-family: "Surt";
            font-size: 65px;
            line-height: 96px;
            color: #000;
            text-decoration: none;
        }
    }
    .contact-phone {
        margin-bottom: 135px;
        a {
            font-family: "Surt";
            font-size: 65px;
            line-height: 96px;
            color: #000;
            text-decoration: none;
        }
    }
    .contact-adress {
        font-family: "Surt";
        font-size: 38px;
        line-height: 80px;
        color: #000;
        margin-bottom: 75px;
    }
    .contact-valid {
        font-family: "Surt";
        font-size: 24px;
        line-height: 80px;
        color: #000;
    }
}

@media (max-width: 1199px) {
    .contact {
        .contact-email {
            margin-top: 35px;
            margin-bottom: 0;
        }
        .contact-phone {
            margin-bottom: 100px;
        }
    }
}

@media (max-width: 991px) {
    .contact {
        .contact-email {
            a {
                font-size: 54px;
            }
        }
        .contact-phone {
            a {
                font-size: 54px;
            }
        }
    }
}

@media (max-width: 767px) {
    .contact {
        .header {
            margin-bottom: 30px;
        }
        .contact-email {
            a {
                font-size: 24px;
                line-height: 45px;
            }
        }
        .contact-phone {
            margin-bottom: 35px;
            a {
                font-size: 24px;
                line-height: 45px;
            }
        }
        .contact-adress {
            font-size: 24px;
            line-height: 48px;
        }
        .contact-valid {
            font-size: 18px;
            line-height: 40px;
        }
    }
}


.whatwedo {
    h1, h2 {
        font-family: "Surt";
        font-weight: bold;
        font-size: 85px;
        line-height: 115px;
        text-align: left;
        color: #000 !important;
        padding-top: 0;
    }
    .whatwedo-header {
        font-family: "Surt";
        font-weight: normal;
        font-size: 85px;
        line-height: 96px;
        text-align: left;
        color: #000;
    }
    .whatwedo-row {
        position: relative;
        padding-top: 150px;
        padding-bottom: 150px;
        &:nth-of-type(2) {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 25px;
                width: calc(100% - 50px);
                border-top: 2px solid #000;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 25px;
                width: calc(100% - 50px);
                border-bottom: 2px solid #000;
            }
        }
        &:first-of-type {
            padding-top: 0;
        }
        &:last-of-type {
            padding-bottom: 0;
        }
        .whatwedo-what {
            p {
                font-family: "Surt";
                font-weight: normal;
                font-size: 38px;
                line-height: 80px;
                text-align: left;
                color: #000;
            }
        }
        .whatwedo-links {
            a {
                display: block;
                font-family: "Surt";
                font-weight: normal;
                font-size: 38px;
                line-height: 80px;
                text-align: left;
                color: #000;
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 1399px) {
    .whatwedo {
        h1, h2 {
            font-size: 54px;
            line-height: 80px;
            padding-bottom: 50px;
        }
        .whatwedo-row {
            .whatwedo-header {
                font-size: 38px;
                line-height: 65px;
                margin-top: 25px;
            }
            .whatwedo-what {
                margin-top: 25px;
                p {
                    font-size: 28px;
                    line-height: 58px;
                }
            }
            .whatwedo-links {
                margin-top: 25px;
                a {
                    font-size: 28px;
                    line-height: 58px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .whatwedo {
        h1, h2 {
            font-size: 56px;
            line-height: 75px;
        }
        .whatwedo-row {
            .whatwedo-header {
                font-size: 48px;
                line-height: 50px;
            }
            .whatwedo-what {
                p {
                    font-size: 30px;
                    line-height: 50px;
                }
            }
            .whatwedo-links {
                a {
                    font-size: 30px;
                    line-height: 50px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .whatwedo {
        h1, h2 {
            font-size: 44px;
            line-height: 70px;
        }
        .whatwedo-row {

            .whatwedo-header {
                font-size: 36px;
                line-height: 40px;
            }

            .whatwedo-what {
                p {
                    font-size: 24px;
                    line-height: 40px;
                }
            }
            .whatwedo-links {
                a {
                    font-size: 24px;
                    line-height: 40px;
                }
            }
        }
    }
}